.text-green {
    color: #008E91;
}

.fs-18 {
    font-size: 18px;
}

.fs-20 {
    font-size: 20px;
}

.fs-42 {
    font-size: 42px;
}

.bg-black {
    background-color: #000000;
}

 .bg-brown {
    background-color: #3C3C3C;
 }