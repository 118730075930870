@media screen and (min-width: 1200px) {
  .navbar-brand .logo {
    width: 100%;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .navbar-brand .logo {
    width: 100px!important;
  }
  .navbar-expand-md .navbar-collapse {
    justify-content: end;
  }

  iframe {
    width: 100%;
    height: calc(100vw/1.77);
  }
  
}

@media screen and (max-width: 767px) {
  .navbar-brand .logo {
    width: 50px!important;
  }

  iframe {
    width: 100%;
    height: calc(100vw/1.77);
  }
}

@media screen and (max-device-width: 480px) {
  .navbar-brand .logo {
    width: 50px!important;
  }
}
