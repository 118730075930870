/*-----------------------------------------------
Main Style
-----------------------------------------------*/
@font-face {
  font-family: Righteous;
  src: url(../fonts/Righteous-Regular.ttf);
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: Century Gothic Pro;
}

html {
  scroll-behavior: smooth;
}

body::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  z-index: -999;
}

body {
  width: 100%;
  height: 100vh;
  background-image: url("../images/AdobeStock_327969979.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

.main {
  display: flex !important;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.main-container {
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px; 
  transition: padding-left 0.3s ease-in-out; 
  */
}

.logo {
  width: 55px;
}

.custom-btn {
  height: 45px!important;
}

.border-custom {
  border:1px solid #686868;
}

::-webkit-scrollbar {
  background-color: #666666 !important;
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #161616 !important;
}

.text-white {
  color: #fff;
}

iframe {
  width: 100%;
  height: calc(100vw / 3);
}

.quote::before {
  content: open-quote;
}
.quote::after {
  content: close-quote;
}

.quote {
  font-family: "Righteous";
  font-size: 20px;
}

li {
  list-style: none;
}

.youtube-caption,
.youtube-storage-output {
  height: 600px !important;
  overflow-y: scroll;
  text-align: left;
  background: #262626;
  color: #fff;
}

.youtube-caption li {
  list-style: none;
}

.youtube-storage {
  height: 600px;
}

.youtube-storage li a {
  margin-right: 10px;
  padding-left: 10px;
}

.youtube-storage-output {
  text-align: left;
}

.youtube-storage-output img {
  width: 100% !important;
  height: auto !important;
}

.donation {
  height: 60px !important;
  width: 160px !important;
}

.previewSection {
  height: 150px;
  background: #ffffff;
  display: none;
}

.currentTime {
  background-color: rgba(0, 0, 0, 0.5);
}

/*-----------------------------------------------
Login / Register
-----------------------------------------------*/

.or-text {
  position: relative;
  padding: 0 10px;
  font-weight: bold;
  color: #fff;
}

.or-text::before,
.or-text::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 100px;
  height: 1px;
  background-color: #fff;
}

.or-text::before {
  left: -110px;
}

.or-text::after {
  right: -110px;
}

/*-----------------------------------------------
Loader
-----------------------------------------------*/
.overlay-loader {
  background: #000;
  opacity: 0.5;
  z-index: -99999;
}

.loader {
  border: 16px solid #f3f3f3;
  /* Light grey */
  border-top: 16px solid #048023;
  /* Dark Green */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spinloader 2s linear infinite;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -100px;
}

.loader img {
  height: 120px;
  width: 120px;
  animation: spinlogo 2s linear infinite;
}

/* Caption Container */
.subItem {
  min-height: 50px;
  padding: 15px;
}

.subItem.imageItem {
  height: auto !important;
}

.timeBox {
  border-radius: 0.5rem;
  border: solid 0.0625rem #d8d8d8;
  font-size: 14px;
  font-weight: 200;
}

.subtitle {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 200;
}

.highlight {
  background-color: #000000;
}

.user-profile {
  flex-direction: column;
  align-items: start;
  justify-content: center;
  min-height: 100vh;
}

/* Caption Container */

#result-container {
  padding: 25px;
  background-color: #3C3C3C;
}

.result {
  background-color: #252525;
  border: 1px solid #686868;
  padding: 20px;
}


@keyframes spinloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes spinlogo {
  0% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(0deg);
  }
}
