/* .removeNode {
  display: none;
} */

.note-container .nav-link {
  color: #ffffff !important;
}

.note-container td :nth-child(n + 4) {
  display: none;
}

.note-container td img {
  width: 30%;
}

.note-container .removeNode {
  display: none;
}

.detail-container a {
  cursor: pointer;
  text-decoration: none;
  font-weight: 700;
}

.detail-container img {
  width: 50%;
  height: auto;
}

.save-button {
  background-color: rgb(10, 25, 41);
  color: #fff; /* Optionally, set the text color to white or any other color that provides enough contrast */
  padding: 10px 20px; /* Add some padding to make the button more visually appealing */
  border: none; /* Remove the default button border */
  border-radius: 5px; /* Optionally, add border radius to make the button edges rounded */
  cursor: pointer; /* Show a pointer cursor on hover to indicate interactivity */
  /* Add any other styling for the button as needed */
}
