.logoContainer {
  width: 100%;
  text-align: center;
  margin: 20px 0px;
  cursor: pointer;
}

.logo {
  width: 80%;
  max-width: 100px;
}

.sidenav {
  width: 150px;
  transition: width 0.3s ease-in-out;
  background-color: rgb(0, 0, 0);
  height: 100vh;
  position: fixed;
}

.sidenavlink {
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  justify-content: start;
  height: 100%;
}

.sidenavClosed {
  composes: sidenav;
  transition: width 0.3s ease-in-out;
  width: 60px;
}

.sideitem {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;
  color: #fff;
  text-decoration: none;
  overflow: hidden;
}

.linkText {
  padding-left: 16px;
}

.sideitem:hover {
  background-color: #244f7d1c;
}

.menuBtn {
  align-self: center;
  align-self: flex-start;
  color: #fff;
  background-color: transparent;
  border: none;
  cursor: pointer;
  /* padding-left: 20px; */
  /* position: absolute; */
  width: 100%;
}

.mainExpanded {
    padding-left: 150px!important;
}

.mainClosed {
    padding-left: 20px!important;
}
